import React from 'react';
import { styled } from 'linaria/react';
import PageContent from './Util/PageContent';
import TextContent from './ui/TextContent';
import { ContentRender } from './ContentRender/ContentRender';

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  align-items: center;
`;

const ErrorTitle = styled('h1')`
  margin: 0 0 1rem 0 !important;
`;

const ErrorBody = styled('div')`
  color: #555;
  max-width: 700px;
  padding-bottom: 1em;
`;

const NotFoundPage = () => {
  return (
    <PageContent pageId={164}>
      {(page) => (
        <>
          <ErrorContainer>
            <ErrorTitle>{page && page.mainHeader}</ErrorTitle>
            <ErrorBody>
              {page?.content && <TextContent content={page.content} />}
            </ErrorBody>
          </ErrorContainer>
          {page?.data && <ContentRender data={page.data} />}
        </>
      )}
    </PageContent>
  );
};

export default NotFoundPage;
